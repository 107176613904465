import i18n from '@/lang/index.js'
import { Message } from 'element-ui'
import { ALERT_TYPE, UPDATE_TYPE } from './enum'

export function isAllValueEmpty (target, message) {
  const isEmpty = value => String(value) === 'null' || String(value) === ''
  switch (typeof target) {
    case 'object':
      if (Object.values(target).every(isEmpty)) {
        if (message) {
          Message({
            message,
            type: 'warning',
            duration: 3 * 1000
          })
        }
        return true
      }
      return false
    case 'array':
      if (target.every(isEmpty)) {
        if (message) {
          Message({
            message,
            type: 'warning',
            duration: 3 * 1000
          })
        }
        return true
      }
      return false
    default:
      return false
  }
}

export function getBaseSettingErrorMessages ({
  excludeEquipments,
  kpiSetting,
  alertSetting,
  progressUpdateSetting: updateSetting,
  worktimes
}) {
  const errMsgs = []

  // 0. 排除事件
  if (excludeEquipments) {
    for (let i = 0; i < excludeEquipments.length; i++) {
      // 0.1 排除機台未選
      if (!excludeEquipments[i].equipment) {
        errMsgs.push(i18n.t('schedule.errorMessage.emptyExcludeEquipment'))
      }
      for (let j = 0; j < excludeEquipments[i].reasons.length; j++) {
        // 0.2 排除時段 結束時間未大於起始時間
        if (excludeEquipments[i].reasons[j].startDatetime === excludeEquipments[i].reasons[j].endDatetime) {
          errMsgs.push(excludeEquipments[i].equipment + ' - ' + i18n.t('schedule.errorMessage.excludedPeriodEndMustLaterThanStart'))
        }
      }
    }
  }
  // 1. 機台重複
  if (excludeEquipments) {
    const eqIds = excludeEquipments.map(e => e.equipment)
    if (new Set(eqIds).size < eqIds.length) {
      errMsgs.push(i18n.t('schedule.errorMessage.duplicatedExcludedEquipment'))
    }
  }
  // 2. KPI 值重複
  if (kpiSetting) {
    if (Object.values(kpiSetting).sort().toString() !== [1, 2, 3, 4].toString()) {
      errMsgs.push(i18n.t('schedule.errorMessage.duplicatedKPI'))
    }
  }
  // 3. 填入不合理的 alert threshold 值
  if (alertSetting) {
    if (alertSetting.alertType === ALERT_TYPE.ALERT_QUANTITY) {
      if (alertSetting.alert_threshold <= 0) {
        errMsgs.push(i18n.t('schedule.errorMessage.invalidAlertThresholdQuantity'))
      }
    } else if (alertSetting.alertType === ALERT_TYPE.ALERT_PERCENTAGE) {
      if (alertSetting.alertThreshold > 100 || alertSetting.alertThreshold <= 0) {
        errMsgs.push(i18n.t('schedule.errorMessage.invalidAlertThresholdPercentage'))
      }
    }
  }
  // 4. 填入不合理的 update interval 值
  if (updateSetting && updateSetting.updateType === UPDATE_TYPE.CUSTOM) {
    if (updateSetting.updateInterval <= 0) {
      errMsgs.push(i18n.t('schedule.errorMessage.invalidUpdateInterval'))
    }
  }
  // 5. 上班時段 結束時間需大於起始時間
  function getMinutes (time) {
    const [start, end] = time.split(':')
    return +start * 60 + +end
  }
  if (worktimes) {
    for (let weekday in worktimes) {
      worktimes[weekday].ranges.forEach(shift => {
        const startTimeMinutes = getMinutes(shift.startTime)
        const endTimeMinutes = getMinutes(shift.endTime)
        if (startTimeMinutes >= endTimeMinutes) {
          errMsgs.push(i18n.t('schedule.setting.weekdayShift', { weekday: i18n.t(`schedule.base.${weekday}`) }) + ' - ' + i18n.t('schedule.errorMessage.invalidShiftPeriod'))
        }
      })
    }
  }
  return errMsgs
}

export function isSplitMergeSettingValid (settingInfo) {
  const copiedSetting = { ...settingInfo }
  // must be positive
  if (parseFloat(copiedSetting.splitThreshold) <= 0 || parseFloat(copiedSetting.mergeThreshold) <= 0) {
    Message({
      message: i18n.t('schedule.splitMergeSetting.thresholdMustBePositive'),
      type: 'warning',
      duration: 3 * 1000
    })
    return false
  }
  // split threshold must greater than merge threshold
  if (parseFloat(copiedSetting.splitThreshold) <= parseFloat(copiedSetting.mergeThreshold)) {
    Message({
      message: i18n.t('schedule.splitMergeSetting.splitThresholdMustGreaterThanMergeThreshold'),
      type: 'warning',
      duration: 3 * 1000
    })
    return false
  }
  return true
}
